<template>
  <ReportForm
    :id="report.id"
    :class="{ flat: isFlat }"
    :header="header"
    :allowToggle="allowToggle"
  >
    <div class="p-formgrid p-grid p-fluid">
      <ReadOnlyField v-if="false" class="p-col" name="Slack Channel" :value="report.channel" />
    </div>
    <div class="p-formgrid p-grid p-fluid">
      <ReadOnlyField
        class="p-col-12"
        name="What is the most important thing you are focusing on today and why is it so important?"
        :value="report.topPriority"
        :basic="false"
      />
      <ReadOnlyField class="p-col-12" name="Tasks for Today" :value="report.tasksForToday" :basic="false" />
    </div>
  </ReportForm>
</template>

<script>
import { computed } from 'vue'

import ReadOnlyField from '../reporting/ReadOnlyField.vue'
import ReportForm from '@/components/custom/ReportForm.vue'
export default {
  name: 'SODFormSummary',
  components: { ReadOnlyField, ReportForm },
  props: {
    report: { type: Object, required: true },
    allowToggle: { type: Boolean, default: true },
    isFlat: { type: Boolean, default: false }
  },
  setup(props) {
    const header = computed(() => {
      const value = {
        title: 'SOD Report',
        slackUrl: props.report?.slackUrl
      }
      if (props.report?.channel) {
        value.tag = {
          value: props.report.channel,
          severity: 'info'
        }
      }
      return value
    })

    return { header }
  }
}
</script>

<style scoped>
</style>
