<template>
  <div class="p-grid p-mt-3">
    <div class="p-col-12 p-my-3" v-for="(report, index) in reports" :key="index">
      <SODFormSummary :report="report" :allowToggle="false" />
    </div>
    <div class="p-col-12">
      <StepNav :next="false" :prev="!isSubmitted" :prevEnabled="!loading" @prevStep="handleBackNav">
        <template v-slot:left-button>
          <Button
            label="Back to Dashboard"
            @click="$router.push({ name: 'utilization', params: { costCenterId: $store.state.selectedCostCenterId } })"
            v-if="isSubmitted"
          />
        </template>
        <template v-slot:right-button>
          <Button
            label="Submit"
            icon="pi pi-check"
            iconPos="right"
            @click="handleSubmitReport"
            :loading="loading"
            v-if="!isSubmitted"
          />
        </template>
      </StepNav>
    </div>
  </div>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

import SODFormSummary from '@/components/reporting/SODFormSummary.vue'
import StepNav from '@/components/custom/StepNav.vue'
import useReport from '../../hooks/useReport'
import { handleHttpError } from '../../utils/utilities'

export default {
  name: 'SODSummary',
  emits: ['prev-page', 'next-page', 'submit-sod'],
  components: { SODFormSummary, StepNav },
  setup(props, { emit }) {
    window.scrollTo(0, 0)

    const route = useRoute()
    const toast = useToast()
    const { error: useReportError, createReport, loading } = useReport()

    // data
    const isSubmitted = ref(false)

    // injected properties
    const reports = inject('reports')

    watch(useReportError, (value) => {
      handleHttpError(value, toast, 'Unable to process your request.')
    })

    // methods
    const handleBackNav = () => emit('prev-page', route.path)
    const handleSubmitReport = async () => {
      const report = { ...reports[0] }
      delete report.type
      await createReport({ type: 'SOD', data: report })
      if (!useReportError.value) {
        isSubmitted.value = true
        emit('submit-sod')
      }
    }

    return { handleBackNav, handleSubmitReport, isSubmitted, loading, reports }
  }
}
</script>

<style scoped>
.page-title {
  margin: 3rem auto !important;
}
.task {
  min-width: 350px !important;
  margin: 1rem 1rem;
}
</style>
