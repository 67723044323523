<template>
  <div class="p-d-flex p-jc-end">
    <slot name="left-button"></slot>
    <Button
      v-if="prev"
      data-test="default-button-prev"
      label="Previous"
      class="p-mr-2 p-button-secondary p-button-outlined"
      icon="pi pi-angle-left"
      :disabled="!prevEnabled"
      :loading="prevLoading"
      @click="$emit('prevStep')"
    />
    <Button
      v-if="next"
      data-test="default-button-next"
      label="Next"
      icon="pi pi-angle-right"
      iconPos="right"
      :disabled="!nextEnabled"
      :loading="nextLoading"
      @click="$emit('nextStep')"
    />
    <slot name="right-button"></slot>
  </div>
</template>

<script>
export default {
  name: 'StepNav',
  emits: ['nextStep', 'prevStep'],
  props: {
    prev: { type: Boolean, default: true },
    next: { type: Boolean, default: true },
    prevEnabled: { type: Boolean, default: true },
    nextEnabled: { type: Boolean, default: true },
    nextLoading: { type: Boolean, default: false },
    prevLoading: { type: Boolean, default: false }
  }
}
</script>
