<template>
  <div class="p-inputtext p-component" style="height: 200px; overflow-y: auto; opacity: 0.38">
    <div data-test="value" v-html="value"></div>
  </div>
</template>

<script>
export default {
  name: 'EditorView',
  props: { value: { type: String, required: true } }
}
</script>
