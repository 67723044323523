<template>
  <LabeledField :label="name" :autoLayout="false">
    <InputText v-if="basic" :value="value" disabled />
    <EditorView :formats="[]" v-else :value="value"></EditorView>
  </LabeledField>
</template>

<script>
import EditorView from '@/components/custom/EditorView.vue'

export default {
  name: 'ReadOnlyField',
  props: {
    name: { type: String, required: true },
    value: { type: [String, Number, Date], required: false },
    basic: { default: true } // use InputText if true, else use EditorView
  },
  components: { EditorView }
}
</script>

<style scoped>
</style>
